import {
    GET_EQUIPA,
    GRAVAR_EQUIPA,
    RESET_INSCRICOES
} from '../types';


export default function inscricoesReducer(state = {}, action) {

    switch (action.type) {
        case GET_EQUIPA:
            let equipaid = null;
            let equipa = null;
            let jogadores = null;
            let staff = null;
            if (action.payload.equipa && action.payload.equipa.length && action.payload.equipa.length > 0 &&
                action.payload.equipa[0].id) {
                equipaid = action.payload.equipa[0].id;
                equipa = action.payload.equipa[0];
            }

            if (action.payload.jogadores && action.payload.jogadores.length && action.payload.jogadores.length > 0) {
                jogadores = action.payload.jogadores;
            }
            if (action.payload.staff && action.payload.staff.length && action.payload.staff.length > 0) {
                staff = action.payload.staff;
            }

            return {
                ...state, equipa: equipa, jogadores: jogadores, staff: staff, equipaid: equipaid, packs: action.payload.packs
            }
        case GRAVAR_EQUIPA:
            return {
                ...state, ...action.payload
            }
        case RESET_INSCRICOES:
            return {}

            default:
                return state
    }
}