import React, { useState } from "react";
import { useSelector } from 'react-redux';
import {Row, Col, Form, FloatingLabel,Card,ListGroup} from 'react-bootstrap';

const FormPack = ({register,errors,changeNome}) => {

  const [selected, setSelected] = useState(useSelector(state=>state.inscricoes.equipa.pack));
  const equipaid = useSelector(state=>state.inscricoes.equipa.id);
  const equipaisenta = useSelector(state=>state.inscricoes.equipa.isento);
  const equipahotel = useSelector(state=>state.inscricoes.equipa.hotel);
  const packs = useSelector(state=> state.inscricoes.packs);

  const returnExtras = ()=>{
      switch(selected){
        case "basico":
          return <>
          <ListGroup>
      <ListGroup.Item>Inscrição</ListGroup.Item>
      <ListGroup.Item>2 almoços</ListGroup.Item>
      <ListGroup.Item>Welcome Kit</ListGroup.Item>
      <ListGroup.Item className="fw-bold" >Preço por elemento: 35€</ListGroup.Item>
    </ListGroup>
          </>
        case "escola":
          return <>
          <ListGroup>
      <ListGroup.Item>Inscrição</ListGroup.Item>
      <ListGroup.Item>Alojamento em escola (saco cama e colchão não incluídos)</ListGroup.Item>
      <ListGroup.Item>2 almoços</ListGroup.Item>
      <ListGroup.Item>2 jantares</ListGroup.Item>
      <ListGroup.Item>2 pequenos almoços</ListGroup.Item>
      <ListGroup.Item>Welcome Kit</ListGroup.Item>
      <ListGroup.Item className="fw-bold" >Preço por elemento: 70€</ListGroup.Item>
    </ListGroup>
          </>
           case "escolaplus":
            return <>
            <ListGroup>
        <ListGroup.Item>Inscrição</ListGroup.Item>
        <ListGroup.Item>Alojamento em escola (saco cama e colchão não incluídos)</ListGroup.Item>
        <ListGroup.Item>2 almoços</ListGroup.Item>
        <ListGroup.Item>2 jantares</ListGroup.Item>
        <ListGroup.Item>2 pequenos almoços</ListGroup.Item>
        <ListGroup.Item>Welcome Kit</ListGroup.Item>
        <ListGroup.Item>Transporte para os jogos</ListGroup.Item>
        <ListGroup.Item className="fw-bold" >Preço por elemento: 80€</ListGroup.Item>
      </ListGroup>
            </>
        case "hotelplus":
        case "isento":
          return <>
                    <ListGroup>
      <ListGroup.Item>Inscrição</ListGroup.Item>
      <ListGroup.Item>Alojamento hotel ou alojamento local</ListGroup.Item>
      <ListGroup.Item>Pequeno almoço incluído</ListGroup.Item>      
      <ListGroup.Item>2 almoços</ListGroup.Item>
      <ListGroup.Item>2 jantares</ListGroup.Item>
      <ListGroup.Item>Welcome Kit</ListGroup.Item>
      <ListGroup.Item>Transporte para os jogos</ListGroup.Item>
      <ListGroup.Item className="fw-bold" >Preço por elemento: 140€</ListGroup.Item>
    </ListGroup></>
    case "hotel":
      return <>
                <ListGroup>
  <ListGroup.Item>Inscrição</ListGroup.Item>
  <ListGroup.Item>Alojamento hotel ou alojamento local</ListGroup.Item>
  <ListGroup.Item>Pequeno almoço incluído</ListGroup.Item>  
  <ListGroup.Item>2 almoços</ListGroup.Item>
  <ListGroup.Item>2 jantares</ListGroup.Item>
  <ListGroup.Item>Welcome Kit</ListGroup.Item>
  <ListGroup.Item className="fw-bold" >Preço por elemento: 130€</ListGroup.Item>
</ListGroup></>
      }
  }

  return ( 
  <> 
  <Card  className='mb-3'><Card.Header>Selecionar Pack</Card.Header><Card.Body>  
  <Row mb="1">
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="packId" label="Pack" className="mb-3">
      <Form.Select isInvalid={errors?.pack} {...register('pack',{ onChange: (e) => {setSelected(e.target.value);changeNome()}})}  aria-label="Pack Básico">
       {
          equipaisenta === 'Y' ? <option value="isento">Isento</option> 
          :     <>
               { React.Children.toArray(
                  packs.map((elem)=>{
                    return <option value={elem.codigo}>{elem.nome}</option>
                  })
                ) }
                </>
       }

      { //Option Pack Hotel only for teams with id 31 to 35
      //equipaid >= 31 && equipaid <= 35 ? <option value="hotel">Pack Hotel</option> : null
      //
     }
    </Form.Select>
    <Form.Control.Feedback type="invalid">                                                     
            {errors?.pack?.message}                                                               
          </Form.Control.Feedback> 
      </FloatingLabel>
    </Col>
    <Col className='col justify-content-center align-items-center'>
      {returnExtras()}
    </Col>
  </Row> 
  </Card.Body> 
  </Card>
  </>
    )
}
    

export default FormPack;